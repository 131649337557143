<template>
	<main>
        <HeaderTab 
            :title="$t('user_management.gestion_admin_utilisateurs')" 
        />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
							<form @submit.prevent="submitForm">
								<b-button 
									type="submit"
									variant="primary"
									:disabled="working"
								>
									{{ $t('user_management.sauvegarder_changements') }}
									<font-awesome-icon v-if="working" :icon="['far', 'spinner']" spin />
								</b-button>

								<b-button 
									class="ml-2"
									variant="secondary"
									@click="addUser"
								>
									{{ $t('user_management.ajouter_utilisateur') }}
								</b-button>

								<b-button 
									class="ml-2"
									variant="secondary"
									@click="addGroup"
								>
									{{ $t('user_management.ajouter_groupe_utilisateurs') }}
								</b-button>

								<h2 class="mt-4 mb-3">{{ $t('user_management.users_groups') }}</h2>
                                <div 
                                    v-for="(group, key) in groups" 
                                    :key="'group_'+key"
                                >
                                    <hr class="mt-4 mb-3">
									<b-form-input 
										v-if="group.changeLabel" 
										type=text 
										v-model="group.label" 
										:autofocus="true" 
										@blur="group.changeLabel = false"
									></b-form-input>
                                    
									<div v-else class="row align-items-center">
                                        <label class="col mb-0 user-mail-management" @click="setGroupEditLabel(group)">
											{{ group.label }}
											<font-awesome-icon :icon="['fal', 'edit']" />
										</label>

										<b-button @click="deleteGroup(group)" variant="secondary">
											<span class="d-none d-sm-inline"><font-awesome-icon :icon="['fal', 'trash-alt']" /></span><span class="d-sm-none"></span>
										</b-button>
									</div>

									<div class="row mt-3">
										<div class="col">
											<e-select
												v-model="group.users"
												id="group_users"
												placeholder="Choisir des utilisateurs à ajouter"
												track-by="email"
												label="email"
												:options="emails"
												:searchable="true"
												:allow-empty="false"
												:loading="loading"
												:multiple="true"
											/>
										</div>
									</div>

									<div class="row mt-3">
										<div class="col">
											<e-select
												v-model="group.rights"
												id="group_modules"
												placeholder="Choisir des modules à activer"
												track-by="code"
												label="label"
												:options="modules_licence_access_code"
												:searchable="true"
												:allow-empty="false"
												:loading="loading"
												:multiple="true"
											/>
										</div>
									</div>
                                </div>

								<hr class="mt-4 mb-3">
								<h2 class="mt-4 mb-3">{{ $t('user_management.users') }}</h2>
                                <div 
                                    v-for="(user, key) in users" 
                                    :key="'user_'+key"
                                >
                                    <hr class="mt-4 mb-3">
									<div v-if="!user.id">
										<b-alert show variant="warning" class="mt-2">
											<font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
											{{ $t('user_management.invoiced_user') }}
										</b-alert>
									</div>
									<b-form-input 
										v-if="user.changeMail" 
										type=text 
										v-model="user.email" 
										:autofocus="true" 
										@blur="addMailToGroupList(user)"
									></b-form-input>
                                    
									<div v-else class="row align-items-center">
                                        <label class="col mb-0 user-mail-management" @click="setUserEditMail(user)">
											{{ user.email }}
											<font-awesome-icon v-show="!user.is_admin" :icon="['fal', 'edit']" />
											<span v-show="user.is_admin" style="color: green;">(Utilisateur principal)</span>
										</label>

										<div class="col-auto" v-show="!user.is_admin">
											<b-button @click="deleteUser(user)" variant="secondary">
												<span class="d-none d-sm-inline"><font-awesome-icon :icon="['fal', 'trash-alt']" /></span><span class="d-sm-none"></span>
											</b-button>
										</div>
									</div>

									<div class="row mt-3">
										<div class="col">
											<e-select
												v-model="user.rights"
												id="user_modules"
												placeholder="Choisir des modules à activer"
												track-by="code"
												label="label"
												:options="modules_licence_access_code"
												:searchable="true"
												:allow-empty="false"
												:loading="loading"
												:multiple="true"
											/>
										</div>
									</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
	import User from "@/mixins/User.js"

	export default {
		name: "UserRightsManagement",
		mixins: [User],
		components: {
            HeaderTab: () => import('@/components/HeaderTab')
		},
		data () {
			return {
				loading: false,
				working: false,
				modules_licence_access_code: [],
				users: [],
				deleted_users: [],
				groups: [],
				deleted_groups: [],
				emails: []
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			async preinit_component() {
				this.getLicenceModulesCode()
					.then(res => {
						this.modules_licence_access_code = []

						res.retour.modules_code.forEach(module_code => {
							this.modules_licence_access_code.push({
								code: module_code,
								label: this.getTrad('types.modules_code.'+module_code)
							})
						})
					})

				this.getUsersToManage()
					.then(res => {
						const temp = res.retour

						temp.forEach(user => {
							user.changeMail = false
							user.oldMail = user.email
							user.rights = []

							user.actual_rights.forEach(module_code => {
								user.rights.push({
									code: module_code,
									label: this.getTrad('types.modules_code.'+module_code)
								})
							})
						})

						this.users = temp

						this.users.forEach((user, i) => {
							this.emails.push({id: i, email: user.email})
						})
					})

				this.getGroupsToManage()
					.then(res => {
						const temp = res.retour

						temp.forEach(group => {
							group.changeLabel = false
							group.oldLabel = group.group_label
							group.label = group.group_label
							group.rights = []

							group.actual_rights.forEach(module_code => {
								group.rights.push({
									code: module_code,
									label: this.getTrad('types.modules_code.'+module_code)
								})
							})

							group.users = []
							group.actual_emails.forEach((email, i) => {
								group.users.push({id: i, email: email})
							})
						})

						this.groups = temp
					})
			},

			async submitForm() {
				if(this.working) return

				this.working = true
				let formData = []
				let deletedFormData = []

				// Users à gérer / ajouter
				this.users.forEach(user => {
					if(user.email) {
						const temp = {
							email: user.email,
							rights: user.rights.map(r => r.code)
						}

						if(user.email != user.oldMail) {
							temp.old_email = user.oldMail
						}

						formData.push(temp)
					}
				})

				// Users à supprimer
				deletedFormData = this.deleted_users.map(user => user.email)

				let groupFormData = []
				let groupDeletedFormData = []

				// Groups à gérer / ajouter
				this.groups.forEach(group => {
					if(group.label && group.users.length > 0) {
						const temp = {
							label: group.label,
							users: group.users,
							rights: group.rights.map(r => r.code)
						}

						if(group.label != group.oldLabel) {
							temp.old_label = group.oldLabel
						}

						temp.deleted_rights = []
						if(group.actual_rights) {
							if(group.actual_rights != group.rights.map(r => r.code)) {
								temp.deleted_rights = group.actual_rights.filter(r => !group.rights.map(r => r.code).includes(r))
							}
						}

						temp.deleted_emails = []
						if(group.actual_emails) {
							if(group.actual_emails != group.users.map(u => u.email)) {
								temp.deleted_emails = group.actual_emails.filter(email => !group.users.map(u => u.email).includes(email))
							}
						}

						groupFormData.push(temp)
					}
				})

				// Groups à supprimer
				groupDeletedFormData = this.deleted_groups.map(group => group.label)

				let groupDeleted = []
				this.deleted_groups.forEach(group => {
					if(group.label && group.users.length > 0) {
						const temp = {
							users: group.users,
							rights: group.rights.map(r => r.code)
						}

						temp.deleted_rights = []
						if(group.actual_rights) {
							temp.deleted_rights = group.actual_rights
						}

						temp.deleted_emails = []
						if(group.actual_emails) {
							temp.deleted_emails = group.actual_emails
						}

						groupDeleted.push(temp)
					}
				})

				this.setUsersAndGroupsToManage(formData, deletedFormData, groupFormData, groupDeletedFormData, groupDeleted)
					.then(() => {
						this.successToast("toast.info_save_succes")
					})
					.finally(() => {
						this.working = false
					})
			},

			setUserEditMail(user) {
				// On interdit la modif d'une adresse mail pour le compte admin
				if(user.is_admin) return
				user.changeMail = true
			},

			addUser() {
				this.users.push({
					changeMail: true,
					rights: [...this.modules_licence_access_code]
				})
			},

			deleteUser(user) {
				this.deleted_users.push(user)

				const index = this.users.indexOf(user)
				this.users.splice(index, 1)
			},

			setGroupEditLabel(group) {
				group.changeLabel = true
			},

			addGroup() {
				this.groups.push({
					changeLabel: true,
					users: [...this.emails],
					rights: [...this.modules_licence_access_code]
				})
			},

			deleteGroup(group) {
				this.deleted_groups.push(group)

				const index = this.groups.indexOf(group)
				this.groups.splice(index, 1)
			},

			addMailToGroupList(user) {
				user.changeMail = false
				this.emails = [];
				this.users.forEach((user, i) => {
					this.emails.push({id: i, email: user.email})
				})
			}
		}
	}
</script>